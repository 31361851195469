import "/src/styles/fonts.css";
import '/src/styles/global.css';
import '/src/styles/navbar.scss';
import '/src/styles/post.scss';
import '/src/styles/page.scss';
import '/src/styles/slick-newsroom.scss';
import '/src/styles/hubspot.scss';
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/500.css"; // Specify weight
import "@fontsource/poppins/600.css"; // Specify weight
require("prismjs/themes/prism-solarizedlight.css")
require("prismjs/themes/prism-okaidia.css");